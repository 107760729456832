@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-pink {
  background-color: #f3ede6;
}
.bg-navy {
  background-color: #212833;
}
.bg-light-blue {
  background-color: #c8e9e7;
}
.bg-light-pink {
  background-color: #fff8e6;
}
